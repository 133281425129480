<template>
  <div class="report-wrapper">
    <div class="header">
      <div>
        <span>车牌号码：</span>
        <span>{{report.carCode}}</span>
      </div>
      <i />
      <div>
        <span>检测时间：</span>
        <span>{{report.checkTime && report.checkTime.substring(0, 10)}}</span>
      </div>
    </div>
    <div class="summary">
      <span class="title">检测结果：本次共检测 {{report.totalCount}} 项</span>
      <div class="items">
        <div class="item">建议更换 <span class="warning">{{report.warningCount}}</span> 项</div>
        <div class="item">留意观察 <span class="observation">{{report.observationCount}}</span> 项</div>
        <div class="item">正常运行 <span class="normal">{{report.normalCount}}</span> 项</div>
      </div>
    </div>
    <div class="detail-table">
      <div class="head">
        <span>项目名称</span>
        <span>最佳标准</span>
        <span>检测结果</span>
        <span>结论</span>
      </div>
      <div class="body">
        <template v-if="report.warningProjectList && report.warningProjectList.length > 0">
          <div class="check-item item-warning" v-for="(item, index) in report.warningProjectList" :key="index">
            <span>{{item.name}}</span>
            <span>{{item.normal}}</span>
            <span>{{item.result}}</span>
            <span class="warning">建议更换</span>
          </div>
          <div class="seperator" v-if="(report.observationProjectList && report.observationProjectList.length > 0) || (report.normalProjectList && report.normalProjectList.length > 0)"/>
        </template>
        <template v-if="report.observationProjectList && report.observationProjectList.length > 0">
          <div class="check-item item-observation" v-for="(item, index) in report.observationProjectList" :key="index">
            <span>{{item.name}}</span>
            <span>{{item.normal}}</span>
            <span>{{item.result}}</span>
            <span class="observation">留意观察</span>
          </div>
          <div class="seperator" v-if="report.normalProjectList && report.normalProjectList.length > 0"/>
        </template>
        <template v-if="report.normalProjectList && report.normalProjectList.length > 0">
          <div class="check-item item-normal" v-for="(item, index) in report.normalProjectList" :key="index">
            <span>{{item.name}}</span>
            <span>{{item.normal}}</span>
            <span>{{item.result}}</span>
            <span class="normal">正常运行</span>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { securityDetail } from 'api/order'
export default {
  data () {
    return {
      report: {},
      id: ''
    }
  },
  methods: {
    getReport () {
      if (!this.id) {
        return
      }

      let params = {
        id: this.id
      }
      securityDetail(params).then((res) => {
        if (res.data.result !== 0) {
          this.$createToast({
            txt: res.data.desc,
            type: 'error',
            mask: true,
            time: 2000
          }).show()
          return
        }

        this.report = res.data.data
      })
    }
  },
  mounted () {
    this.id = this.$route.query['id'] || ''
    if (this.id === '') {
      this.$createToast({
        txt: '无效的单号',
        type: 'error',
        mask: true,
        time: 2000
      }).show()
      return
    }

    this.getReport()
  }
}
</script>

<style lang="scss" scoped>
@import "~styles/index";

.report-wrapper {
  background: rgba(250, 250, 250, 1);
  padding-top: px2rem(20px);
  min-height: 100vh;
}

.header {
  background: #ffffff;
  padding: px2rem(32px) px2rem(30px);
  font-weight: 400;
  display: flex;
  align-items: center;
}

.header div {
  flex: 1;
  display: flex;
  align-items: center;
}

.header i {
  width: px2rem(2px);
  height: px2rem(30px);
  background: rgba(228, 228, 228, 1);
}

.header div:last-child {
  justify-content: flex-end;
}

.header div span:first-child {
  height: px2rem(33px);
  line-height: px2rem(33px);
  font-size: px2rem(24px);
  color: rgba(153, 153, 153, 1);
  padding-left: px2rem(10px);
}

.header div span:last-child {
  line-height: px2rem(28px);
  font-size: px2rem(28px);
  color: rgba(54, 54, 54, 1);
}

.summary {
  margin-top: px2rem(20px);
  background: #ffffff;
  padding: px2rem(30px) px2rem(40px);
  margin-bottom: px2rem(20px);
}

.summary .title {
  height: px2rem(28px);
  font-size: px2rem(28px);
  font-weight: 600;
  color: rgba(54, 54, 54, 1);
  line-height: px2rem(28px);
  margin-bottom: px2rem(20px);
  display: block;
}

.summary .items {
  display: flex;
}

.summary .item {
  height: px2rem(40px);
  font-size: px2rem(28px);
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  line-height: px2rem(40px);
  display: flex;
  align-items: center;
  margin-right: px2rem(20px);
}

.summary .item::before {
  content: "";
  width: px2rem(8px);
  height: px2rem(8px);
  border-radius: 50%;
  background: rgba(204, 204, 204, 1);
  margin-right: px2rem(10px);
}

.warning {
  margin: 0 px2rem(10px);
  color: #fc4c5a;
}

.observation {
  margin: 0 px2rem(10px);
  color: #ff9d4d;
}

.normal {
  margin: 0 px2rem(10px);
  color: #87cc69;
}

.detail-table {
  background: #ffffff;
  padding: px2rem(30px) px2rem(40px);
  width: px2rem(670px);
  margin: 0 px2rem(0px) px2rem(139px) px2rem(0px);
  display: inline-block;
}

.detail-table .head {
  height: px2rem(40px);
  font-size: px2rem(28px);
  font-weight: 600;
  color: rgba(54, 54, 54, 1);
  line-height: px2rem(40px);
  display: flex;
  justify-content: space-between;
}

.detail-table .head span,
.detail-table .body .check-item span {
  flex: 1;
  display: inline-block;
}

.detail-table .body .check-item span {
  padding-right: px2rem(10px);
}

.detail-table .head span:last-child,
.detail-table .body .check-item span:last-child {
  width: px2rem(112px);
  text-align: right;
  padding-right: 0;
}

.detail-table .body .check-item {
  font-size: px2rem(28px);
  font-weight: 400;
  color: rgba(54, 54, 54, 1);
  line-height: px2rem(40px);
  display: flex;
  justify-content: space-between;
}

.detail-table .body .check-item {
  padding-top: px2rem(30px);
}

.detail-table .body .check-item .warning,
.detail-table .body .check-item .observation,
.detail-table .body .check-item .normal {
  margin: 0;
}

.detail-table .body .seperator {
  padding-bottom: px2rem(30px);
  border-bottom: px2rem(1px) solid rgba(240, 240, 240, 1);
}

.back-home {
  position: fixed;
  bottom: px2rem(60px);
  width: 100%;
}

.back-home button {
  height: px2rem(80px);
  line-height: px2rem(80px);
  font-size: px2rem(32px);
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  background: rgba(255, 157, 77, 1);
  border-radius: px2rem(6px);
  margin: px2rem(18px) px2rem(40px);
}
</style>
